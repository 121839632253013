@font-face {
  font-family: Neue-Aachen-Pro;
  src: url('/public/font/NeueAachenProRegular.TTF') format('woff2');
  font-weight: normal;
  /* Specify font weight if needed */
  font-style: normal;
  /* Specify font style if needed */
}

:root {
  --main-color: #374785;
  --primary-color: #CF2929;
  --gradient-red: linear-gradient(7deg, #F76D6D, #CF2929, #F76D6D);
  --gradient-blue: linear-gradient(7deg, #6072B5, #374785, #6072B5);

}

body,
html {
  font-family: "Manrope", sans-serif;
  font-size: 16px;
  overflow-x: hidden; 
}
 
a {
  color: inherit !important;
  text-decoration: none !important;
  color: inherit !important;
}
.logo img {
  width: 400px;
  margin: auto;
  display: block;
  max-width: 100%;
}
.highlight-blk{
  background: var(--main-color);
  color: #fff;
  margin-bottom: 0;
  text-align: center;
}
.lazyload-wrapper {
  display: initial;
}
/* .highlight-blk img{
  width: 50px;
} */
.highlight-blk p{
  margin-bottom: 0;
}
.highlight-blk marquee p {
  margin-bottom: 0;
  font-size: 15px;
}
.highlight-blk marquee p a{margin-right:10px;border-right: solid 1px #fff;padding-right:10px;}
.header-contact {
  vertical-align: middle;
  /* height: 100%; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 26px;
  padding-bottom: 20px;
}
.header-contact >div  img {
height: 20px;
}
.pd-0{
padding: 0;
} 
.highlight-blk img {
width: 33px;
}
.header-contact h3 {
  font-size: 20px;
  font-weight: 500;
  color: var(--main-color);
}
.header-contact h3 svg {
  margin-right: 10px;
}
.header-contact > div {
margin-bottom: 0;
width: 40%;
text-align: center;
}
.header-contact  .border-tab{  
background: var(--gradient-blue);
color: #fff;
padding: 5px 4px;
border-radius: 5px;
margin-bottom: 0;
transition: 0.5s;
cursor: pointer;
}
.border-tab:hover{
background: var(--gradient-red);
}
.d-flex{
display: flex;
}
.space-even{
justify-content: space-evenly;
}
.col-md-2.highlight-tab .space-even img {
width: 19px !important;
filter: invert();
} 
.menu {
width: 100%;
float: left;
height: 0;
}
.border-tab{
position: relative;
}
.border-tab::before{
position: absolute;
background: url(https://webapi.entab.info/api/image/STHSM/public/Images/border-tab.png); 
}
 
.menu-toggle {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  display: none;
  
}

.submenu {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  width: max-content;
  background: var(--gradient-blue);
  margin: auto;
  z-index: 999;
  position: relative;
  border-radius: 5px;
  padding: 16px;
}
.submenu li:last-child {
  border-right: 0;
}
.submenu li {
  padding: 0 10px;
  position: relative;
  width: max-content;
  color: #fff;
  margin: 0 10px;
  font-weight: 500;
  text-transform: uppercase;
  border-right: 1px solid;
  font-size: 15px;
  cursor: pointer;
}

.submenu li:last-child {
  border-bottom: none;
}

.menu:hover .submenu {
  display: flex;
}

.sub-submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0%;
  background-color: #fff; 
  padding-left: 0;
  list-style-type: none;    
  padding: 9px 5px;
  box-shadow: 0px 0px 9px #bfbfbf;
  border-radius: 5px;
}
.sub-submenu li {
  color: #000;
  font-size: 15px;
  border-right: 0;
  padding: 7px 0px;
}
.sub-submenu li:hover{
color: var(--primary-color);
cursor: pointer;
}

.submenu li:hover .sub-submenu {
  display: block;
}
.ph-only{
  display: none
}
.sub-submenu li{
  color: #000;
  text-transform: capitalize;
  font-family: "Manrope", font-family;
}
.submenu li i.bi.bi-chevron-down {
  margin-left: 5px;
  font-size: 11px;
}
.header-contact .border-tab a {
position: relative;
}


.vision-mission-col h3,
.title h3 {
  text-transform: uppercase;
  font-family: Neue-Aachen-Pro;
}

.breadcrumb-blk h2 {
  font-family: Neue-Aachen-Pro;
  font-size: 40px;
}

p {
  line-height: 29px;
}

ul li {
  line-height: 29px;
  font-size: 16px;
  margin-bottom: 10px;
}

img.img-fluid {
  width: 100%;
}

.online-reg {
  position: fixed;
  right: 30px;
  z-index: 9;
  top: 180px;
  filter: drop-shadow(0px 0px 6px #ddd);
  width: 190px;
}

.online-reg img {
  width: 100%;
}

.highlight-tab {
  background: var(--gradient-red);
  height: 35px;
}

.highlight-tab a {
  padding: 1px;
}

.header-contact .border-tab {
  position: relative;
}

.header-contact .border-tab:before {
  content: "";
  width: 100%;
  height: 159%;
  background: url(https://webapi.entab.info/api/image/STHSM/public/Images/border-tab.png);
  position: absolute;
  left: 0;
  top: 0;
  background-size: 100% 100%;
  transform: scale(1.1);
  transform-origin: center;
  background-position: center;
  transform: translate(-50%, -50%) scale(1.055);
  left: 50%;
  top: 50%;
}

.header-blk {
  padding: 8px;
}

.vision-mission {
  position: relative;
  display: block;
  top: -100px;
  width: max-content;
  margin: auto;
  background: var(--gradient-blue);
  color: #fff;
  z-index: 9;
  padding: 30px;
  border-radius: 6px;
}



.vision-mission h3 img {
  width: 50px;
}

.vision-mission h3 {
  text-align: center;
  margin-top: 0;
  font-size: 28px;
  font-weight: normal;
  filter: drop-shadow(1px 2px 2px black);
}

.title {
  text-align: center;
  margin-bottom: 30px;
  color: var(--main-color)
}

.title .title-gif {
  width: max-content;
  margin: auto;
  border-bottom: 4px solid #f76d6d;
  padding: 2px 10px;
}

.title .title-gif img {
  width: 55px;
}

p span {
  color: var(--main-color);
  font-weight: 600;
} 
.button a {
  margin-left: auto;
  display: block;margin: auto;
  width: max-content;
  padding: 6px 16px;
  border-radius: 5px;
  background: var(--gradient-blue);
  color: #fff !important;
}

.button a:hover {
  background: var(--gradient-red);
}

.min-ht {
  min-height: 600px;
}

.about-us {
  margin-top: -40px;
  position: relative;
  padding: 40px 0;
}

.about-us:before {
  content: "";
  width: 100%;
  height: 100%;
  background: url(https://webapi.entab.info/api/image/STHSM/public/Images/about-bg.png);

  background-size: 100% 100%;
  right: 0;
  top: 0;
  position: absolute;
  background-position: left;

}

.about-us>div {
  position: relative;
  z-index: 9;
}

.news-bg {
  background: url(https://webapi.entab.info/api/image/STHSM/public/Images/news.png);
  background-size: 100% 100%;
}

.news {
  width: 65%;
  margin: auto;
  padding: 30px 30px 0px;
  border-radius: 6px;
}

.news-blk marquee {
  height: 330px;
}
.event-blk {
  margin-bottom: 30px;
}
.news .button a {
  margin: auto;
}

.news-blk {
  margin-bottom: 140px;
}

.news-event {
  margin-top: 40px;
}

.events {
  width: 90%;
  margin: auto;
  background: #eaeaea;
  padding: 30px 30px 60px 30px;
  border-radius: 6px;
  padding-bottom:10px;
}

.evt-img {
  position: relative;
}
.evt-img img{
  height: 230px;
  object-fit: contain;
  background: #ededed;
  width: 100%;
}

.evt-img .date {
  position: absolute;
  right: 4px;
  top: 4px;
  background: var(--primary-color);
  color: #fff;
  text-align: center;
  padding: 4px 8px;
  border-radius: 9px;
  font-size: 13px;
}

.evt-title {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #374785cc;
  color: #fff;
  padding: 8px 13px;
  border-radius: 10px;
}

.evt-title h3 {
  margin: 0;
  font-size: 19px;
}

.evt-img .date span {
  display: block;
}

.evt-blk {
  background: #fff;
  padding: 6px;
  border-radius: 16px;
  width: 95%;
  margin-bottom: 20px;
}

.evt-blk p img {
  width: 14px !important;
  float: left;
  vertical-align: middle;
  margin-top: 8px;
  margin-right: 7px;
}

.evt-blk p span {
  float: right;
  color: #000;
  font-weight: normal;
}

.evt-content {
  padding: 30px 20px 2px;padding-top:10px;
}

.evt-content p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.evt-content p:last-child {
  font-size: 14px;
}

p.newshead {
  display: flex;
  justify-content: space-between;
  background: #eaeaea;
  padding: 10px;
  border-radius: 5px;
}

p.newshead i {
  background: var(--primary-color);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}

.message {
  width: 90%;
  margin: 0px auto 40px;
}


 #myImg, #myImg1, #myImg2 {
  width: 100%;
  border: 5px solid rgba(158, 158, 158, 0.1);
  background: #eaeaea;
  cursor: pointer;
  transition: 0.3s;
}
.erp_sec img {
  width: 200px;
  display: inline-block;
}
.erp_sec h4.imgheading {
  text-align: center;
}
.assistance {
  background: #eaeeff;
  padding: 20px;
  margin-top: 30px;
}

.message-block {
  display: flex;
  align-items: flex-start;
}

.message-block .message-content {
  width: 70%;
  padding: 40px 80px 40px 40px;
  border-radius: 30px;
  position: relative;
  background: url(https://webapi.entab.info/api/image/STHSM/public/Images/message-bg.png);
  background-size: 100% 100%;
}


.message-image:before {

  content: "";
  width: 100%;
  height: 97%;
  position: absolute;
  transform: translate(-50%, -50%) scale(1.005);
  left: 50%;
  top: 50%;
  background: url(https://webapi.entab.info/api/image/STHSM/public/Images/message-border.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.message-image {
  position: relative;
  padding: 26px;
  width: 30%;
}

.message-image img {
  border-radius: 38%;
  width: 100%;
}

.facilities {
  width: 90%;
  margin: 60px auto 40px;
}

.faci-blk {
  width: 80%;
  margin: auto;
  text-align: center;
  background: #eaeaea;
  padding: 40px 20px;
  border-radius: 7px;
  transition: 0.5s;
}

.faci-blk:hover {
  box-shadow: 0px 0px 20px 0px #c5c5c5;
}

.faci-blk img {
  height: 70px;
  margin-bottom: 20px;
}

.faci-blk p {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
}

.activity-blk h5 {
  text-align: center;
  font-size: 17px;
  margin-bottom: 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.activity-blk img {
  margin: auto;
  display: block;
  margin-bottom: 20px;
}

.activity-blk {
  border-bottom: 5px solid #0000;
  padding-bottom: 20px;
  transition: 0.5s;
}

.activity-blk:hover {
  border-bottom: 5px solid var(--primary-color);
}

.school-activities {
  background: url(https://webapi.entab.info/api/image/STHSM/public/Images/activity-bg.png);
  background-size: 100% 100%;
  margin-top: 40px;

  width: 100%;
  padding: 0 5%;
  margin: 60px auto 40px;
}

.birth-topper {
  background: url(https://webapi.entab.info/api/image/STHSM/public/Images/birthday.png);
  background-size: 100% 100%;
  margin-top: 40px;

  width: 100%;
  padding: 0 5%;
  margin: 60px auto 40px;
}

.birthday-blk {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background: linear-gradient(93deg, #ffffffab, transparent);
  padding: 10px;
  border-radius: 21px;
}

.birthday-blk .img img {
  border-radius: 20px;
  width: 100px;
}

.birthday-blk .img {
  margin-right: 14px;
}

.birthday-blk .content p.primary {
  color: var(--primary-color);
}

.birthday-blk h3 {
  font-size: 18px;
}

.topper-img img {
  width: 110px !important;
  margin: auto;
}

.topper-img {
  background: url(https://webapi.entab.info/api/image/STHSM/public/Images/topper-bg.png);
  
  padding: 43px 24px 20px 24px;
  background-position: center;
  background-repeat: no-repeat;
}

p.percent {
  text-align: center;
  font-size: 14px;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gradient-blue);
  border-radius: 50%;
  color: #fff;
  margin: auto;
  margin-bottom: 10px;
}

.topper-cont {
  text-align: center;
  margin-bottom: 20px;
}

.topper-cont p {
  margin-bottom: 4px;
}

.tpr-block {
  position: relative; 
  background: linear-gradient(181deg, #ffffffb0, #ffffff52);
  margin-bottom: 12px;
}

.tpr-class-img {
  position: absolute;
  left: -40px;
  top: -30px;
}

.gallery,
.footer {
  width: 90%;
  margin: 60px auto 40px;
}

.footer {
  margin-bottom: 0;
}

.gallery .button {
  display: block;
  margin: auto;
  width: max-content;
  text-align: center;
  margin-top: 40px;
}

.lg-mid {
  height: 600px;
  object-fit: cover;
  border: 2px solid #fff;
  border-radius: 15px;object-fit: cover;
}

img.img-fluid.lg-small {
  height: 300px;
  border: 4px solid #fff;
  border-radius: 14px;object-fit: cover;
}

.inr-event-img img{    height: 250px;
  object-fit: contain;}

.title h3 {
  font-size: 27px;
}

footer {
  background: #f5f5f5;
  padding-top: 10px;
}

.footer>div img {
  width: 330px;
}

.footer iframe {
  height: 200px;
  border: 5px solid #fff;
  box-shadow: 1px 2px 5px 0px #646464;
}

.app-cont {
  width: 70%;
}

.app-cont img {
  width: 130px !important;
}

.phone-img img {
  width: 100% !important;
}

.phone-img {
  width: 30%;
}

.quick-link ul {
  padding: 0 0px;
}

l {
  position: relative;
  list-style: none;
}

.quick-link ul li:before {
  left: 0;
  content: "";
  width: 0;
  height: 0;
  border-left: 9px solid var(--main-color);
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  position: absolute;
  border-radius: 2px;
  top: 11px;
}

.quick-link ul li {
  padding-left: 21px;
  list-style: none;
  position: relative;
  padding: 3px 20px;
}

.footer .d-flex {
  align-items: flex-end;
  margin-top: -70px;
}

.footer .link {
  background: var(--main-color);
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  margin-top: 16px;
  width: max-content;
  max-width: 100%;
}

.copyright p {
  text-align: center;
  margin-bottom: 0;
  padding: 5px 0;
  font-size: 12px;
}

/* INNER PAGE */
.breadcrumb {
  padding: 0;
  background: url(https://webapi.entab.info/api/image/STHSM/public/Images/footer-img.png);
  background-size: 100% auto;
  background-position: bottom;
}

.breadcrumb-blk ul {
  display: flex;
  list-style: none;
  padding: 0;
}

.breadcrumb-blk ul li {
  padding: 0 5px;
}

.breadcrumb-blk {
  min-height: 250px;
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(179deg, transparent, #000000a8, #000000ba);
  color: #fff;
}

.inner-img {
  border: 5px solid #fff;
  margin-bottom: 30px;
  box-shadow: 0px 0px 0px 3px var(--main-color);
  border-radius: 10px;
}

.inner-img img {
  border-radius: 10px;
}

h3.sub-title {
  /* border-left: 4px solid #cf2929; */
  padding: 0px 40px;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-family: 'Neue-Aachen-Pro';
  color: var(--primary-color);
  position: relative;
}

h3.sub-title:before {
  width: 31px;
  content: "";
  height: 6px;
  background: var(--primary-color);
  position: absolute;
  left: 0;
  top: 10px;
  border-radius: 5px;
}

.patrons {
  border: 8px solid #3e4e8d;
  margin-bottom: 30px;
  padding-bottom: 16px;
  box-shadow: 0px 0px 11px 0px #9d9d9d;
}

.patrons p {
  text-align: center;
  font-size: 23px;
  font-weight: 500;
  font-family: 'Neue-Aachen-Pro';
  margin-bottom: 0;
  margin-top: 3px;
}

.patrons-cont {
  min-height: 70px;
}

.patrons img {
  margin-bottom: 20px;
}

.patrons p span {
  font-family: "Manrope", sans-serif;
  font-size: 70%;
}

.inner-page {
  padding: 40px 0;
}

.sub-space {
  margin-bottom: 20px !important;
}

.timing .content h3 {
  margin: 0;
  margin-bottom: 10px;
}
.timing .content p{
  margin-bottom: 0;
}

.timing .content {
  padding-left: 20px;
}

.timing {
  margin-bottom: 20px;
}


.tabs.house-tab button:nth-child(1) {
  background: #1c1ccd;
}

.tabs.house-tab button:nth-child(2) {
  background: #ecec1b;
  color: #000
}

.tabs.house-tab button:nth-child(3) {
  background: #02d631;
  color: #000
}

.tabs.house-tab button:nth-child(4) {
  background: #f81e1e;

}

.tabs.house-tab button {
  width: 100%;
  margin-bottom: 20px;
  border: 0;
  padding: 14px 0px;
  border-radius: 3px;
  color: #fff;
  font-size: 18px;
}

.bluehouse {
  border: 3px solid #1c1ccd;
  padding: 15px;
  border-radius: 5px;
  background: #1c1ccd0d;
}

.yellowhouse {
  border: 3px solid #ecec1b;
  padding: 15px;
  border-radius: 5px;
  background: #ecec1b1a;
}


.greenhouse {
  border: 3px solid #02d631;
  padding: 15px;
  border-radius: 5px;
  background: #02d6310f;
}

.redhouse {
  border: 3px solid #f81e1e;
  padding: 15px;
  border-radius: 5px;
  background: #f81e1e0f;
}

.house-block {
  margin-top: 20px;
}

.syllabus-tab a {
  display: flex;
  font-size: 18px;
  align-items: center;
  font-weight: 600;
}

.syllabus-tab a img {
  margin-right: 10px;
}

.syllabus-tab {
  padding: 20px;
  box-shadow: 0px 0px 5px 0px #ddd;
  margin-bottom: 30px;
  border-radius: 5px;
  transition: 0.5s;
}

.syllabus-tab:hover {
  background: var(--gradient-blue);
  color: #fff;
}

.table-responsive {
  margin-bottom: 20px;
}

.table-responsive td {
  padding: 5px;
  border: 1px solid #ddd;
}

.table-responsive table {
  width: 100%;
}

.table-responsive th {
  padding: 5px;
  text-align: center;
  background: var(--main-color);
  color: #fff;
  border: 1px solid #ffffff94;
}

td[colspan] {
  text-align: center;
  font-weight: 600;
  padding: 10px;
  font-size: 18px;
  background: #ebebeb;
}

marquee p {
  display: inline;
  padding: 0 5px;
}

/* .marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.marquee p {
  display: inline-block; 
  animation: marquee 10s linear infinite;
}
.marquee:hover p{
  animation-play-state: paused;
  -moz-animation-play-state: paused;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
} */
.toppers-inner {
  padding: 10px;
  min-height: 308px;
  margin-bottom: 20px;
  background: #42437024;
  border-radius: 3px;
  box-shadow: inset 0px 0px 13px 5px #3d3e6857;
}
.toppers-inner img {
  margin: auto;
  width: 150px;
  height: 140px;
  border-radius: 3px;
  margin-top: 20px;
  display: block;
  border: 6px solid #fff;
} 
.toppers-inner  h3{    color: #333!important;
  text-align: center;
  font-size: 16px !important;
  line-height: 25px !important;
  margin: 0px;
  margin-top: 10px;} 
.toppers-inner  p{text-align:center;margin-bottom: 0;}
.toppers-inner  h4{font-size: 15px;text-align: center;} 
.toppers-inner  h5{    margin: 0px;
  color: #ac2121;
  font-size: 15px;
  text-align: center;
  line-height: 25px;} 
.tabs-dynamic {
  background: #efefef;
  padding: 20px 0 10px;
  border-radius: 7px;
  margin-bottom: 40px;
}

.tabs-dynamic select, .tabs-dynamic input {
  width: 100%;
  padding: 2px 6px;
  border-radius: 5px;
  border: 1px solid #000;
}

.inr-news-blk {
  border: 1px solid #ddd;
  padding: 5px 11px;
  border-radius: 6px;margin-bottom:20px;
}

.inr-news-blk h3 {
  margin-top: 0;
  font-size: 19px;
  color: var(--main-color);
}

.inr-news-blk h6.loc {
  color: #ef5f5f;
  font-size: 13px;
}

.inr-event-blk {
  background: #efefef;
  box-shadow: 2px 4px 5px 0px #0000001f;
  padding: 12px;
}

.inr-event-blk h6.loc a {
  float: right;
  color: #4a5b9b;
}

.inr-event-content h6.loc {
  color: #5263a4;
}

.inr-event-content h6.loc span {
  margin-left: 6px;
}

.inr-event-content h3 {
  font-size: 18px;
  margin-bottom: 2px;
}

.inr-event-content p {
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  min-height: 110px;
}

.inr-event-content h6.date {
  position: absolute;
  bottom: 100%;
  background: var(--gradient-blue);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  right: 10px;
}

.inr-event-content {
  position: relative;
  min-height: 162px;
}

.month-selection {
  margin-bottom: 13px;
}

.inr-event-content h6.loc i {
  margin-right: 2px;
}


.gal_new {
  padding: 9px;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 5px;
  position: relative;
}

.gal_new .total-img {
  display: flex;
  position: absolute;
  top: 0;
  background: var(--main-color);
  color: #fff;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  left: 0;
  border-radius: 3px;
}

.gal_new .total-img p {
  margin: 0;
  margin-left: 5px;
}

.gal-content h4.catgry-name {
  margin-bottom: 1px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.gal-content h4 + p {
  display: -webkit-box;
  min-height: 60px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.gal-content p {
  font-size: 14px;
}

.gal-content p strong {
  color: var(--primary-color);
}

.gal-content h6.date span {
  float: right;
}
.row.zoompic img {
  border: 5px solid #fff;
  box-shadow: 0px 0px 3px 0px #000000b5;height: 250px;object-fit: cover;
}
#slideshowAnim {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #000000bf !important;
}

#slideshowAnim .imageModal {
  margin-left: auto;
  display: block;
  width: max-content;
}
.mt-20 {
  margin-top: 20px;
}

.videodetails a {
  display: flex;
  box-shadow: 0px 0px 5px 0px #ddd;
  margin-bottom: 20px;
  padding: 13px;
  border-radius: 5px;
  align-items: center;
}
.videodetails a img {
  width: 47px;
  margin-right: 20px;
}
.videodesc span {
  font-size: 13px;
}
.videodetails h3 {
  margin-top: 0;
  font-size: 20px;
  color: #374785;
}

.show {
  opacity: 1 !important;
  background: #00000052 !important;
} 
 .popup .carousel-control-next, .popup .carousel-control-prev { width: auto; }
 .popup .carousel-control-next { right: -15px; }
 .popup .modal-dialog { min-width: 800px; transform: translateY(-100px); }
 .pop-slider.modal-header { background: #0a4685; padding: 13px 0 0 }
 .popup .modal-dialog img { width: 100%; object-fit: contain;max-height: 60vh; }
 .pop-slider .modal-body h1 { padding-bottom: 0; text-align: center; font-size: 17px; color: #343131; overflow: hidden; font-weight: 600; text-transform: capitalize; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; margin-bottom: 10px; line-height: 28px; }
 .pop-slider .modal-dialog .modal-header .btn-close { color: #fff; padding-right: 31px }
 .pop-slider .modalcard .card-body { padding: 10px }
 .pop-slider .modalcard .card-body p { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; line-height: 35px; text-align: center; padding-top: 10px; line-height: 26px; }
 .pop-slider .modalcard { border: none }
 .pop-slider .modal-body { padding: 0px 2rem; }
 .popup .modal-content { padding: 0 0 10px }
 div#exampleModalToggle .modal-dialog.modal-dialog-centered {
  transform: translate(0, -50%);
  top: 41%;
}

.pop-slider button.btn-close:focus { box-shadow: none }
.pop-slider a.btn.btn-primary.btn-sm {    margin: 0 10px;
  color: #fff !important;}
.pop-slider a.btn.btn-primary.btn-sm:focus { box-shadow: none; border: 1px solid #182b3c; }
.pop-slider button.btn-close {position: absolute;z-index: 999;
  background-color: #182b3c;
  right: 0;
  color: #fff;
  padding: 5px 10px;
  top: 0;
  border: 0;}
.pop-slider .btn-close {opacity: 1; padding: 10px; background-position: center; border-radius: 0; }
 .modal-bottom { display: flex; flex-direction: row; justify-content: center; align-items: center; margin-top: 10px; }
 
.contact_Us img {
  width: 36px;
  margin-right: 20px;
  margin-top: 20px;
}
iframe {
  border: 3px solid #4b5c9c;
}
.birthday marquee {
  height: 350px;
}
.patrons.d-flex img {
  width: 170px;
  margin-bottom: 0;
}
.cont-dtl img {
  width: 18px !important;
}
.patrons.d-flex {
  padding: 20px 10px;
}
.house-cont .tab-content img {
  width: 199px;
  float: right; 
  margin-bottom: 20px;
  margin-left: 10px;
}


.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9
}

.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden
}

.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid #fff
}

.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%
}

.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center
}

.magnify-footer .magnify-toolbar {
  display: inline-block
}

.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #055a91;
}

.magnify-button:hover {
  color: #fff;
  background-color: #e00000
}

.magnify-button-close:hover {
  background-color: #e00000
}

.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #333
}

.magnify-loader::before {
  content: '';
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden
}

@-webkit-keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

@keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}
.facilities_img .inner-img img{
  height: 230px;
  object-fit: contain;
  background: #ebebeb;
}
.gal_new img {
  height: 160px;
  object-fit: contain;
  background: #efefef;
}
p.visitorCount {
  background: var(--gradient-red);
  color: #fff;
  padding: 2px 10px;
  width: max-content;
  max-width: 100%;
  border-radius: 8px;
}
@media(max-width: 1600px) {
  .header-contact .border-tab {
    font-size: 13px;
  }
  .header-contact .border-tab:before {
    content: "";
    width: 101%;
    height: 160%;
    background-repeat: no-repeat;
    background-size: 100% 88%;
    transform: translate(-50%, -50%) scale(1.1);
}

  .footer .d-flex {
    align-items: flex-end;
    margin-top: 00px;
  }

  .faci-blk {
    width: 98%;
    padding: 40px 10px;
    margin-bottom: 10px;
  }

  .topper-img {
 
    padding: 43px 20px;
  }
}


@media(max-width: 1400px) {
  .header-contact > div {
    width: 42%;
    font-size: 14px;
}
.logo img {
  width: 370px; 
}
.header-contact >div img {
height: 15px;
}

  .footer .link {
    font-size: 13px;
  }

  .evt-content {
    padding: 20px 6px 2px;
  }

  .evt-content p:last-child {
    font-size: 13px;
  }

  .topper-img img {
    width: 90px !important;
    margin: auto;
    height: 90px;
  }
}

@media(max-width: 1200px) {

  .announcement{
    display: none;
  }
  .sub-submenu{
    padding: 9px 12px;
  }
  .submenu li {
    color: #fff;
    margin: 0 0px;
    font-size: 14px;
}
.sub-submenu li {
  color: #000 !important;
}
  .online-reg {
    right: 30px;
    top: 200px;
    width: 140px;
  }

  .patrons p {
    font-size: 20px;
  } 

  .min-ht {
    min-height: 370px;
  }

  .footer .d-flex {
    margin-top: 0;
  }

  .news {
    width: 65%;
    margin: auto;
    padding: 20px 30px 90px;
    border-radius: 6px;
  }
}

@media(max-width: 991px) {

  .submenu li i.bi.bi-chevron-down{
    float: right;
  }
  .sub-submenu {
    position: static;
    margin-top: 16px;
}

.sub-submenu li {
    color: #000;
}
  .desk-only{
    display: none;
  }
  .header-contact{
    flex-wrap: wrap;
    padding-bottom: 0;
  }
  .birthday marquee {
    height: 260px;
}

  .header-contact > div { 
    margin-bottom: 20px;
}
  .ph-only{
    display: block
  }
  .col-md-2.highlight-tab {
    display: none;
}
  .submenu {
    list-style-type: none;
    padding: 0;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    z-index: 1;
    display: block;
    border: none;
    width: 100%;
    flex-direction: column;
    padding: 20px 0;
    width: 98%;
    margin: 0 1%;
    box-shadow: -1px 1px 5px 0px #000000a1;
    z-index: 99;
  }

  .menu-toggle {
    display: block;
    background: var(--primary-color);
    z-index: 9999;
    position: absolute;
    right: 20px;
    border-radius: 10px;
    top: -190px;
  }
  .submenu li{
      width: 100%;
      padding:5px 20px;
      border: 0;
  }
  .menu{
    position: relative;
  }
  .logo img {
    width: 280px;
  }
 

  .online-reg {
    right: 0;
    top: 50%;
    transform: translate(-10%, -117%);
  }

  .phone-img img {
    width: 52% !important;
  }

  .message-block .message-content:before {
    display: none;
  }

  .lg-mid {
    margin-bottom: 20px;
  }

  .news-area {
    margin-bottom: 20px;
  }

  .message-block {
    flex-direction: column-reverse;
    align-items: center;
  }

  

  .message-block .message-content {
    width: 95%;
  }

  .news-blk marquee {
    min-height: 390px;
  }


  .app-cont img {
    width: 120px !important;
  }

  .footer .link {
    text-align: center;
    font-size: 13px;
  }
}
.notification {
  position: relative;
  z-index: 99989;
}
@media(max-width: 767px) {
  .about-us {
    margin-top: 0;
  }
  .popup .modal-dialog {
    min-width: 80%;
}

  .about-us:before {
    display: none;
  }

  .patrons p {
    font-size: 28px;
  }

  .patrons-cont {
    height: auto;
  }

  .min-ht {
    min-height: 290px;
  }

  .news {
    padding-bottom: 20px;
  }

  .topper-img {
    background-size: 67% 86%;
    padding: 43px 20px;
  }

  .vision-mission {
    position: relative;
    top: 0;
    width: 96%;
  }

  .tpr-block {
    padding-left: 30px;
  }

  .topper-img img {
    width: 110px !important;
    height: 110px;
  }
}

@media(max-width:600px) {
  .house-cont .tab-content img {
    width: 100%;
    float: unset;
    margin-bottom: 20px;
    margin-left: 0px;
}
 .logo img { 
      margin-left: 0;
  }
  .vision-mission h3,
  .title h3 {
    font-size: 24px;
  }

  .patrons p {
    font-size: 22px;
  }

  .phone-img img {
    width: 100% !important;
  }

  .topper-img {
    background-size: 50% 86%;
  }

  .message-block .message-content {
    width: 100%;
    padding: 0;
    box-shadow: none;
    background: none;
  }

  .vision-mission {
    position: static;
    width: 95%;
    padding: 10px;
  }

  p {
    font-size: 14px;
  }

  .news-bg {
    background: unset;
  }

  .news {
    width: 100%;
    margin: auto;
    padding: 30px 30px 0px;
    border-radius: 6px;
    background: #eaeaea;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .events {
    width: 100%
  }

  .news-blk {
    margin-bottom: 20px;
  }

  .min-ht {
    min-height: 190px;
  }

  .online-reg {
    width: 140px;
  }
}

@media(max-width: 400px) {
 
  .ph-only {
    display: none;
}
.menu {
  height: auto;
  float: unset;
}

.menu-toggle {
  position: static;
  width: 100%;
  padding: 2px;
  border-radius: 0;
  text-align: right;
  padding: 2px 11px;
}

.header-contact > div {
    margin-bottom: 0 !important;
}
.header-blk {
  padding: 8px 8px 20px;
}
.header-contact .border-tab:before {
  content: "";
  width: 100%;
  height: 144%; 
  background-size: 100% 88%; 
}
  .app-cont,
  .phone-img {
    width: 100%;
  }

  .min-ht {
    min-height: 120px;
  }

  .online-reg {
    width: 110px;
  }
  .house-cont .tab-content img {
    width: 100%;
    float: unset;
    margin-bottom: 20px;
    margin-left: 0px;
}
}